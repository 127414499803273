import Vue from 'vue'
//import VueAwesomeSwiper from 'vue-awesome-swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
// import "swiper/components/pagination/pagination.min.css"
/*
import SwiperCore, {
  Autoplay,
  Pagination
} from 'swiper/core';
SwiperCore.use([Autoplay, Pagination]);
*/

document.addEventListener('DOMContentLoaded', () => {
  //Vue.use(VueAwesomeSwiper);
  const app = new Vue({
    el: '#swiper_container',
    components: {
      Swiper,
      SwiperSlide
    },
    directives: {
      swiper: directive
    },
    data: {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 32,
        centeredSlides: false,
        loop: false,
        breakpoints: {
          767: {
            slidesPerView: 2,
          },
          1271: {
            slidesPerView: 3,
          }
        },
        autoplay: {
          delay: 6500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper_pagination',
          clickable: true,
        }
      },
    },
    created: function () {
    },
    methods: {
    }
  })
})
